
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserIcon",
  props: {
    height: {
      type: Number,
      default: 32,
    },
  },
});
