export function generateMyMemosUrl(): string {
  return "/mymemos";
}

export function generateMyPageUrl(): string {
  return "/mypage";
}

export function generateUserUrl(uid: string): string {
  return "/users/" + uid;
}

export function generateEditMemoUrl(memoId: string): string {
  return "/memos/" + memoId + "/edit";
}

export function generateAddMemoUrl(): string {
  return "/memos/add";
}

export function generateRoomListUrl(): string {
  return "/rooms";
}

export function generateRoomUrl(roomId: string): string {
  return "/rooms/" + roomId;
}

export function generateRoomPostUrl(roomId: string, postId: string): string {
  return "/rooms/" + roomId + "/" + postId;
}

export function generateAddRoomUrl(): string {
  return "/rooms/add";
}

export function generateTimelineUrl(): string {
  return "/timeline";
}

export function generateLoginUrl(): string {
  return "/login";
}

export function generateAccountSettingUrl(): string {
  return "/account/setting";
}

export function generateFollowListUrl(): string {
  return "/follows";
}

export function generateSearchUrl(): string {
  return "/search";
}
