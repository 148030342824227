import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { DefaultApolloClient } from "@vue/apollo-composable";

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_BASE_URL,
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

import { initialize } from "./databases/firebase/init";
import VueClickAway from "vue3-click-away";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const db = initialize();

const app = createApp(App)
    .use(VueClickAway)
    .use(store)
    .use(router)
    .use(Toast, { timeout: 2000, position: "bottom-right" })
    .provide(DefaultApolloClient, apolloClient);
app.mount("#route");

export { db };
