import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: "33",
    height: _ctx.height,
    viewBox: "0 0 33 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M25.8599 13.3333V25.3333H7.16661V6.66667H19.1666V4H7.19328C5.72661 4 4.52661 5.2 4.52661 6.66667V25.3333C4.52661 26.8 5.72661 28 7.19328 28H25.8599C27.3266 28 28.5266 26.8 28.5266 25.3333V13.3333H25.8599ZM23.1666 13.3333L24.4199 10.5867L27.1666 9.33333L24.4199 8.08L23.1666 5.33333L21.9133 8.08L19.1666 9.33333L21.9133 10.5867L23.1666 13.3333ZM18.1666 14.3333L16.4999 10.6667L14.8333 14.3333L11.1666 16L14.8333 17.6667L16.4999 21.3333L18.1666 17.6667L21.8333 16L18.1666 14.3333Z",
      fill: _ctx.isActive ? '#ffb300' : '#4F4F4F'
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}