
import { defineComponent } from "vue";
export default defineComponent({
  name: "CommentIcon",
  props: {
    height: {
      type: Number,
      default: 20,
    },
  },
});
