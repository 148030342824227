import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Index",
    component: () =>
      import(
        /* webpackChunkName: "TimelinePage" */ "../views/TimelinePage.vue"
      ),
  },
  {
    path: "/rooms",
    name: "Room List",
    component: () =>
      import(
        /* webpackChunkName: "RoomListPage" */ "../views/RoomListPage.vue"
      ),
  },
  {
    path: "/rooms/add",
    name: "New Room",
    component: () =>
      import(/* webpackChunkName: "AddRoomPage" */ "../views/AddRoomPage.vue"),
  },
  {
    path: "/rooms/:id",
    name: "Room",
    component: () =>
      import(/* webpackChunkName: "RoomPage" */ "../views/RoomPage.vue"),
  },
  {
    path: "/rooms/:id/:postId",
    name: "Room Post",
    component: () =>
      import(
        /* webpackChunkName: "RoomPostPage" */ "../views/RoomPostPage.vue"
      ),
  },
  {
    path: "/mymemos",
    name: "My Memo List",
    component: () =>
      import(
        /* webpackChunkName: "MyMemoListPage" */ "../views/MyMemoListPage.vue"
      ),
  },
  {
    path: "/mypage",
    name: "My Page",
    component: () =>
      import(/* webpackChunkName: "MyPagePage" */ "../views/MyPagePage.vue"),
  },
  {
    path: "/memos/add",
    name: "New Memo",
    component: () =>
      import(/* webpackChunkName: "AddMemoPage" */ "../views/AddMemoPage.vue"),
  },
  {
    path: "/memos/:id/edit",
    name: "Edit Memo",
    component: () =>
      import(
        /* webpackChunkName: "EditMemoPage" */ "../views/EditMemoPage.vue"
      ),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPage.vue"),
  },
  {
    path: "/timeline",
    name: "Timeline Page",
    component: () =>
      import(
        /* webpackChunkName: "TimelinePage" */ "../views/TimelinePage.vue"
      ),
  },
  {
    path: "/users/:id",
    name: "User Page",
    component: () =>
      import(/* webpackChunkName: "UserPage" */ "../views/UserPage.vue"),
  },
  {
    path: "/account/setting",
    name: "Account Setting Page",
    component: () =>
      import(
        /* webpackChunkName: "AccountSettingPage" */ "../views/AccountSettingPage.vue"
      ),
  },
  {
    path: "/follows",
    name: "FollowList Page",
    component: () =>
      import(
        /* webpackChunkName: "FollowListPage" */ "../views/FollowListPage.vue"
      ),
  },
  {
    // experimental
    path: "/log",
    name: "Log",
    component: () =>
      import(/* webpackChunkName: "LogPage" */ "../views/LogPage.vue"),
  },
  {
    // experimental
    path: "/estimated",
    name: "Estimated",
    component: () =>
      import(
        /* webpackChunkName: "EstimatedPage" */ "../views/EstimatedPage.vue"
      ),
  },
  {
    // experimental
    path: "/suggest",
    name: "Suggest",
    component: () =>
      import(/* webpackChunkName: "SuggestPage" */ "../views/SuggestPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
