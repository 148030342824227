
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import GlobalSidebar from "./components/GlobalSidebar.vue";

import { defineComponent, onMounted, reactive, watch, onUnmounted } from "vue";
import "./index.css";
import { convertEntityToModel, User } from "./models/User";
import { getUser } from "./databases/firebase/query/users";
import { generateAddMemoUrl } from "./utilities/UrlGenerator";
import { RouteLocationNormalized, useRouter } from "vue-router";

export default defineComponent({
  name: "App",
  setup() {
    const state = reactive<{
      loginUser: User | null;
      firebaseUid: string | null;
      rightContentShown: boolean;
    }>({
      loginUser: null,
      firebaseUid: null,
      rightContentShown: true,
    });

    const router = useRouter();
    router.afterEach((to: RouteLocationNormalized) => {
      state.rightContentShown = shouldShow_(to.fullPath);
    });

    watch(
      () => state.firebaseUid,
      (user) => {
        if (!user) {
          return;
        }
        getUser(user).then((userEntity) => {
          if (!userEntity) {
            return;
          }
          state.loginUser = convertEntityToModel(userEntity);
        });
      }
    );

    onMounted(() => {
      firebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          state.firebaseUid = firebaseUser.uid;
        } else {
          // router.push(generateLoginUrl());
        }
      });
    });

    function shouldShow_(toHref: string) {
      // メモ編集画面は広告を非表示にする
      return (
        toHref.includes(generateAddMemoUrl()) ||
        new RegExp("/memos/\\w+/edit$").test(toHref)
      );
    }

    return {
      state,
    };
  },
  components: {
    GlobalSidebar,
  },
});
