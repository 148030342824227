import { db } from "@/main";
import { converter } from "../models/UserEntity";
import { converter as followConverter } from "../models/FollowEntity";
import { UserResponse } from "./entities/UserResponse";

const getUser = async (userUid: string): Promise<UserResponse|null> => {
  if (!userUid) {
    return null;
  }
  const user = await db
    .collection("userDataWithoutSecret")
    .doc(userUid)
    .withConverter(converter)
    .get();
  const data = user.data();
  if (!user || !data) {
    return null;
  }
  return {
    ...data,
  };
};

const getUsers = async (): Promise<UserResponse[]> => {
  const users = await db
    .collection("userDataWithoutSecret")
    .withConverter(converter)
    .get();
  return users.docs.map((doc) => doc.data());
};

const getFollowUsersByUserUid = async (userUid: string): Promise<UserResponse[]> => {
  if (!userUid) {
    return [];
  }
  const follows = await db
    .collection("follows")
    .where("followerUid", "==", userUid)
    .where("followTargetType", "==", "user")
    .withConverter(followConverter)
    .orderBy("followedAt", "desc")
    .get();
  const users = await Promise.all(
    follows.docs.map(async (doc) => {
      const followData = doc
        .data();
      if (!followData) {
        return null;
      }
      const ref = await followData
        .followTargetRef.withConverter(converter)
        .get();
      return {
        ...ref.data(),
      };
    })
  );
  return users.filter(u => !!u) as UserResponse[];
};

const checkFollowingUser = async (
  loginUserUid: string,
  targetUserUid: string
): Promise<boolean> => {
  const followUser = await db
    .collection("follows")
    .where("followerUid", "==", loginUserUid)
    .where("followTargetType", "==", "user")
    .where("followTargetId", "==", targetUserUid)
    .withConverter(followConverter)
    .get();
  if (followUser.docs.length < 1) {
    return false;
  }
  const data = followUser.docs[0].data();
  if (!data) {
    return false;
  }
  const userUid = data.followTargetId;
  const userRef = await db
    .collection("userDataWithoutSecret")
    .doc(userUid)
    .get();
  if (!userRef || !userRef.data()) {
    return false;
  }

  return true;
};

export { getUser, getUsers, getFollowUsersByUserUid, checkFollowingUser };
