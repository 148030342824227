import firebase from "firebase/compat/app";

export type FollowEntity = {
  id: string;
  followTargetId: string;
  followTargetType: string;
  followTargetRef: firebase.firestore.DocumentReference;
  followedAt: Date;
  followerUid: string;
  timelineTitle: string;
};

export const converter = {
  toFirestore(follow: FollowEntity): firebase.firestore.DocumentData {
    return {
      followTargetId: follow.followTargetId,
      followTargetType: follow.followTargetType,
      followedAt: follow.followedAt,
      followerUid: follow.followerUid,
      timelineTitle: follow.timelineTitle,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): FollowEntity {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      followTargetId: data.followTargetId,
      followTargetType: data.followTargetType,
      followTargetRef: data.followTargetRef,
      followedAt: data.followedAt?.toDate(),
      followerUid: data.followerUid,
      timelineTitle: data.timelineTitle,
    };
  },
};
