import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: "33",
    height: _ctx.height,
    viewBox: "0 0 33 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M16.4867 2.6665C9.12675 2.6665 3.16675 8.63984 3.16675 15.9998C3.16675 23.3598 9.12675 29.3332 16.4867 29.3332C23.8601 29.3332 29.8334 23.3598 29.8334 15.9998C29.8334 8.63984 23.8601 2.6665 16.4867 2.6665ZM16.5001 26.6665C10.6067 26.6665 5.83341 21.8932 5.83341 15.9998C5.83341 10.1065 10.6067 5.33317 16.5001 5.33317C22.3934 5.33317 27.1667 10.1065 27.1667 15.9998C27.1667 21.8932 22.3934 26.6665 16.5001 26.6665Z",
      fill: _ctx.isActive ? '#ffb300' : '#4F4F4F'
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M17.1667 9.3335H15.1667V17.3335L22.1667 21.5335L23.1667 19.8935L17.1667 16.3335V9.3335Z",
      fill: _ctx.isActive ? '#ffb300' : '#4F4F4F'
    }, null, 8, _hoisted_3)
  ], 8, _hoisted_1))
}