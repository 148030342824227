import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: "33",
    height: _ctx.height,
    viewBox: "0 0 33 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M19.1667 8V28H4.5V25.3333H7.16667V4H19.1667V5.33333H25.8333V25.3333H28.5V28H23.1667V8H19.1667ZM13.8333 14.6667V17.3333H16.5V14.6667H13.8333Z",
      fill: _ctx.isActive ? '#ffb300' : '#4F4F4F'
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}