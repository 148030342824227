
import { defineComponent } from "vue";

export default defineComponent({
  name: "ThreeDotsIcon",
  props: {
    height: {
      type: Number,
      default: 4,
    },
  },
});
