
import { defineComponent } from "vue";

export default defineComponent({
  name: "BookMarkIcon",
  props: {
    height: {
      type: Number,
      default: 32,
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
  },
});
