import { UserEntity } from "@/databases/firebase/models/UserEntity";

export type User = {
  id: string;
  uid: string;
  name: string;
  profileImageUrl?: {
    thumbnail: string;
  };
};

export function convertEntityToModel(entity: UserEntity) {
  return {
    id: entity.id,
    uid: entity.uid,
    name: entity.name,
    profileImageUrl: entity.profileImageUrl,
  };
}
