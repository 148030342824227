
import { defineComponent } from "vue";
export default defineComponent({
  name: "CloseIcon",
  props: {
    height: {
      type: Number,
      default: 24,
    },
  },
});
