
import { defineComponent } from "vue";

export default defineComponent({
  name: "ThumbsUpIcon",
  props: {
    isFilled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 20,
    },
  },
});
