
import { defineComponent } from "vue";

export default defineComponent({
  name: "LinkIcon",
  props: {
    height: {
      type: Number,
      default: 10,
    },
  },
});
