import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export const initialize = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBEQDr3Q05AXnpnoEMWafUjkWMAhztitPg",
    authDomain: "solomon-373.firebaseapp.com",
    databaseURL: "https://solomon-373.firebaseio.com",
    projectId: "solomon-373",
    storageBucket: "solomon-373.appspot.com",
    messagingSenderId: "446846978032",
    appId: "1:446846978032:web:2f97033adb34b5ec82ff35",
    measurementId: "G-BCX6SY6FCT"
  };

  firebase.initializeApp(firebaseConfig);
  return firebase.firestore();
}

export class FirebaseDb {
  db_: firebase.firestore.Firestore | null = null;
  app: firebase.app.App | null = null;

  getDb(): Promise<firebase.firestore.Firestore> {
    if (this.db_ === null) {
      return fetch("/__/firebase/init.json").then(async (response) => {
        if (firebase.apps.length === 0) {
          this.app = firebase.initializeApp(await response.json());
        }
        if (this.app === null) {
          this.app = firebase.apps[0];
        }
        this.db_ = this.app.firestore();
        return this.db_;
      });
    }
    return Promise.resolve(this.db_);
  }
}
