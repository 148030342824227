
import { defineComponent, PropType, reactive } from "vue";
import {
  AccessTimeIcon,
  AccountBoxIcon,
  MeetingRoomIcon,
  PhotoFilterIcon,
  SearchForHeaderIcon,
  MenuListIcon,
  PlusIcon,
  UserIcon,
} from "@/components/icons/index";
import { useRouter } from "vue-router";
import {
  generateAddMemoUrl,
  generateLoginUrl,
  generateMyMemosUrl,
  generateMyPageUrl,
  generateRoomListUrl,
  generateTimelineUrl,
  generateAccountSettingUrl,
  generateFollowListUrl,
  generateSearchUrl,
} from "@/utilities/UrlGenerator";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { User } from "@/models/User";

export default defineComponent({
  name: "GlobalSidebar",
  components: {
    AccessTimeIcon,
    AccountBoxIcon,
    MeetingRoomIcon,
    PhotoFilterIcon,
    SearchForHeaderIcon,
    UserIcon,
    PlusIcon,
  },
  props: {
    loginUser: {
      type: Object as PropType<User | null>,
      default: null,
    },
  },
  setup() {
    const router = useRouter();

    const TabTypes = {
      TIMELINE: "timeline",
      ROOMS: "rooms",
      MEMOS: "memos",
      MY_PAGE: "myPage",
    };

    const urls = {
      timelineUrl: generateTimelineUrl(),
      roomsUrl: generateRoomListUrl(),
      memosUrl: generateMyMemosUrl(),
      myPageUrl: generateMyPageUrl(),
      newMemoUrl: generateAddMemoUrl(),
      searchUrl: generateSearchUrl(),
    };

    function getActiveTab(pathname: string): string {
      if (pathname.startsWith(urls.timelineUrl)) {
        return TabTypes.TIMELINE;
      } else if (pathname.startsWith(urls.roomsUrl)) {
        return TabTypes.ROOMS;
      } else if (pathname.startsWith(urls.memosUrl)) {
        return TabTypes.MEMOS;
      } else if (pathname.startsWith(urls.myPageUrl)) {
        return TabTypes.MY_PAGE;
      }
      return "";
    }

    const state = reactive({
      currentActiveTab: getActiveTab(location.pathname),
      isShownAccountMenuPopup: false,
      isOpenMobileMenu: false,
    });

    function handleLoginClick() {
      router.push(generateLoginUrl());
    }

    function handleAccountProfileClick() {
      state.isShownAccountMenuPopup = !state.isShownAccountMenuPopup;
    }

    function handleAccountProfileOutSideClick() {
      state.isShownAccountMenuPopup = false;
    }

    function handleLogoutLick() {
      firebase.auth().signOut();
    }
    function handleMobileMenuOpen() {
      state.isOpenMobileMenu = !state.isOpenMobileMenu;
    }

    return {
      urls,
      state,
      TabTypes,
      navigateToTop: () => {
        router.push("/");
      },
      navigateTo: (url: string) => {
        state.currentActiveTab = getActiveTab(url);
        router.push(url);
      },
      handleLoginClick,
      handleAccountProfileClick,
      handleAccountProfileOutSideClick,
      handleLogoutLick,
      accountSettingUrl: generateAccountSettingUrl(),
      handleMobileMenuOpen,
      followListUrl: generateFollowListUrl(),
    };
  },
});
