import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: "33",
    height: _ctx.height,
    viewBox: "0 0 33 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M4.5 6.66667V25.3333C4.5 26.8 5.68667 28 7.16667 28H25.8333C27.3 28 28.5 26.8 28.5 25.3333V6.66667C28.5 5.2 27.3 4 25.8333 4H7.16667C5.68667 4 4.5 5.2 4.5 6.66667ZM20.5 12C20.5 14.2133 18.7133 16 16.5 16C14.2867 16 12.5 14.2133 12.5 12C12.5 9.78667 14.2867 8 16.5 8C18.7133 8 20.5 9.78667 20.5 12ZM8.5 22.6667C8.5 20 13.8333 18.5333 16.5 18.5333C19.1667 18.5333 24.5 20 24.5 22.6667V24H8.5V22.6667Z",
      fill: _ctx.isActive ? '#ffb300' : '#4F4F4F'
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}