import firebase from "firebase/compat/app";

export type UserEntity = {
  name: string;
  uid: string;
  id: string;
  profileImageUrl?: {
    thumbnail: string;
  };
};

export const converter = {
  toFirestore(user: UserEntity): firebase.firestore.DocumentData {
    return {
      id: user.id,
      uid: user.uid,
      name: user.name,
      profileImageUrl: user.profileImageUrl,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): UserEntity {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = snapshot.data(options)!;
    return {
      id: data.id,
      uid: data.uid,
      name: data.name,
      profileImageUrl: data.profileImageUrl,
    };
  },
};
